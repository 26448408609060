<template>
  <div>
    <div v-if="!allChecklists">
      <b-card>
        <p>This section will be available when you apply in university.</p>
      </b-card>
    </div>
    <div v-else class="p-2 rounded-lg" style="background-color: #fbfbfb">
      <b-card class="mb-0 border rounded-lg">
        <app-collapse class="">
          <app-collapse-item title="Sample of All Required Documents">
            <b-row>
              <b-col
                v-for="(doc, docIndex) in filteredChecklistDocs"
                :key="'doc_' + docIndex"
                cols="12"
                md="3"
              >
                <span>{{ doc.acd_name }}</span>
                <br />
                <embed
                  :src="$FILESURL + doc.acd_doc"
                  width="200px"
                  height="300px"
                />
                <br />
                <span>
                  <a :href="$FILESURL + doc.acd_doc" class="mr-1">Open</a>

                  <!-- {{ doc.doc_id }} -->
                  <b-button
                    size="sm"
                    variant="primary"
                    v-b-modal.add-doc-modal
                    @click="setDocId(doc.doc_id)"
                    >Upload</b-button
                  >
                </span>
              </b-col>
            </b-row>
          </app-collapse-item>
        </app-collapse>
      </b-card>

      <div class="d-flex justify-content-center">
        <div class="w-75">
          <hr />
        </div>
      </div>
      <b-card class="border rounded-lg">
        <app-collapse>
          <app-collapse-item
            class="mt-0"
            v-for="(checklist, checklist_index) in allChecklists"
            :key="'checklist_' + checklist_index"
            :title="checklist.ac_name"
          >
            <b-card class="mb-2">
              <app-collapse class="border overflow-hidden rounded-lg shadow-sm">
                <app-collapse-item title="Sample of Required Documents">
                  <b-row>
                    <b-col
                      v-for="(doc, docIndex) in getDocsByAcId(checklist.ac_id)"
                      :key="'doc_' + docIndex"
                      cols="12"
                      md="3"
                    >
                      <span>{{ doc.acd_name }}</span>
                      <br />
                      <embed
                        :src="$FILESURL + doc.acd_doc"
                        width="200px"
                        height="300px"
                      />
                      <br />
                      <span>
                        <a :href="$FILESURL + doc.acd_doc" class="mr-1">open</a>
                        <b-button
                          size="sm"
                          variant="primary"
                          v-b-modal.add-doc-modal
                          @click="setDocId(doc.doc_id)"
                          >Upload</b-button
                        >
                      </span>
                    </b-col>
                  </b-row>
                </app-collapse-item>
              </app-collapse>
            </b-card>
            <div style="margin-inline: 14px">
              <!-- <button
                class="btn btn-outline-primary btn-sm mb-1"
                @click="
                  downloadPDF('checklist_' + checklist_index, checklist.ac_name)
                "
              >
                Download Checklist
              </button> -->
              <div class="mb-2">
                <div
                  :id="'checklist_' + checklist_index"
                  style="padding: 4% 4% 4% 4%; border: 1px solid black"
                  class="font-weight-bolder shadow-sm"
                  v-html="checklist.ac_text"
                ></div>
              </div>
            </div>
          </app-collapse-item>
        </app-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
import { BCard, BCol, BRow, BButton } from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import StudentServices from "@/apiServices/StudentServices";
import html2pdf from "html2pdf.js";

export default {
  components: { BCard, AppCollapse, AppCollapseItem, BCol, BRow, BButton },
  data() {
    return {
      allChecklists: [],
      allChecklistsDocs: [],
      filteredChecklistDocs: [],
    };
  },
  methods: {
    getDocsByAcId(acId) {
      return this.allChecklistsDocs.filter((acd) => acd.ac_id == acId);
    },

    downloadPDF(id, title) {
      html2pdf(document.getElementById(id), {
        margin: 5,
        filename: title + ".pdf",
      });
    },

    getFilteredChecklistDocs() {
      let arr = [];
      for (let item of this.allChecklistsDocs) {
        let repeatFound = false;
        for (let chkItem of arr) {
          if (chkItem.doc_id === item.doc_id) {
            repeatFound = true;
          }
        }

        if (!repeatFound) {
          arr.push(item);
        }
      }

      return arr;
    },
    setDocId(id) {
      // Emit an event to notify the parent component to change the value
      this.$emit("set-doc-id", id);
    },
    async getApplicationChecklist() {
      try {
        const response = await StudentServices.applicationChecklist();

        if (response.data.status) {
          this.allChecklists = response.data.data.ac;
          this.allChecklistsDocs = response.data.data.ac_docs;
          this.filteredChecklistDocs = this.getFilteredChecklistDocs();
        }
      } catch (err) {
        console.log("Error in getting application checklist ", err);
      }
    },
  },
  beforeMount() {
    this.getApplicationChecklist();
  },
};
</script>

<style></style>
